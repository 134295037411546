<template>
  <main class="page page-appeals">
    <BreadcrumbsComponent :title="currentTitle" :links="breadcrumbs" />
    <div class="page__container">
      <div class="page__content">
        <AsideNavigatorComponent :routes="asideLinksWithAppeals" />
        <RouterView :key="$route.fullPath" />
      </div>
    </div>
  </main>
</template>
<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import AsideNavigatorComponent from "components/navigation/AsideNavigatorComponent.vue";
import APPEALS from "gql/queries/appeals.graphql";

export default {
  name: "AppealsPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        variables: {
          id: parseInt(route.params.id),
        },
        query: APPEALS,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  data() {
    return {
      asideLinks: [
        {
          title: "Отправить обращение",
          to: {
            name: "appeals-form",
          },
        },
        {
          title: "Порядок рассмотрения обращений граждан",
          to: {
            name: "appeals-about",
          },
        },
        {
          title: "Часто задаваемые вопросы",
          to: {
            name: "appeals-faq",
          },
        },
      ],
    };
  },
  computed: {
    currentTitle() {
      const staticAsideIndex = this.asideLinksWithAppeals
        .map((l) => {
          return l.to.name;
        })
        .findIndex((l) => {
          return l === this.$route.name;
        });
      if (this.$route.params?.id) {
        return this.$store.state.appeals_posts_item?.title;
      }
      return this.asideLinksWithAppeals[staticAsideIndex]?.title;
    },
    appeals() {
      return this.$store.state.appeals_posts;
    },
    asideLinksWithAppeals() {
      return this.asideLinks.concat(
        this.appeals.map((a) => ({
          title: a.title,
          to: {
            name: "appeals-posts",
            params: {
              id: a.id,
            },
          },
        }))
      );
    },
    breadcrumbs() {
      return [
        {
          title: "Обращения граждан",
          to: "",
        },
      ];
    },
  },
  metaInfo: {
    title: "Обращения граждан",
  },
  components: { AsideNavigatorComponent, BreadcrumbsComponent },
};
</script>

<style lang="stylus">
.page-appeals {
  .page__content {
    grid-template-columns 328px 1fr
    grid-gap 30px
    align-items start
    +below(1280px) {
      grid-template-columns 1fr
    }
  }
}
</style>
